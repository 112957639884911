import { render, staticRenderFns } from "./new.vue?vue&type=template&id=64fdb48e&scoped=true"
import script from "./new.vue?vue&type=script&lang=js"
export * from "./new.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64fdb48e",
  null
  
)

export default component.exports